<template>
  <div>
    <div class="h-[100vh] w-[200px] bg-[#f7f7f7] text-black fixed shadow-lg">
      <div
        class="w-full bg-black text-white text-[18px] border shadow-xl flex justify-center items-center font-bold py-5"
      >
        <div class="flex-col justify-center items-center w-full">
          <div class="flex justify-center items-center">
            <img src="@/assets/green_delta.png" alt="" class="h-[3rem]" />
          </div>
          <div class="text-xs">Asset Tracker</div>
        </div>
      </div>
      <div class="w-full flex justify-center mt-14 text-[12px] font-bold">
        <div
          class="w-5/6 bg-white py-4 pl-4 rounded shadow flex flex-col items-start"
        >
          <div>{{ name }}</div>
          <div>{{ role }}</div>
        </div>
      </div>

      <!-- routers link -->
      <div class="flex flex-col mt-10 text-[12px] font-bold relative">
        <router-link
          :to="`/home`"
          class="py-3 w-full hover:bg-white flex pl-6 items-start"
          >Home</router-link
        >
        <hr class="bg-gray-200 w-full h-[1px]" />
        <router-link
          :to="`/register_user`"
          class="py-3 w-full hover:bg-white flex pl-6 items-start"
        >
          Registration</router-link
        >
        <hr class="bg-gray-200 w-full h-[1px]" />
        <router-link
          :to="`/userList`"
          class="py-3 w-full hover:bg-white flex pl-6 items-start"
          >User List</router-link
        >
        <hr class="bg-gray-200 w-full h-[1px]" />
        <div
          class="py-3 w-full hover:bg-red-200 flex pl-6 items-start"
          @click="logout()"
        >
          LogOut
        </div>
        <hr class="bg-gray-200 w-full h-[1px]" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import api from "../../../boot/axios.js";

const router = useRouter();
const name = ref(localStorage.getItem("name"));
const role = ref(localStorage.getItem("role"));
const instance = getCurrentInstance();
const logout = async () => {
  try {
    localStorage.clear();
    await router.push("/");
    instance.proxy.$swal({
      toast: true,
      position: "bottom-end",
      icon: "success",
      title: "You have successfully logged out!",
      timerProgressBar: true,
      showConfirmButton: false,
      timer: 2000,
    });
  } catch (error) {
    console.log(error, "Error during logout");
  }
};
</script>
