<template>
  <div id="content" style="height: 100vh">
    <div class="h-[80px] w-full bg-slate-900">
      <div class="grid grid-cols-2 justify-center items-center h-full">
        <div class="h-full flex justify-center">
          <img src="@/assets/green_delta.png" alt="" class="h-[80px]" />
        </div>
        <div class="h-full"></div>
      </div>
    </div>

    <div class="">
      <div class="">
        <div class="grid place-items-center h-[70vh]">
          <div class="w-[400px] rounded overflow-hidden shadow-lg">
            <div class="flex justify-center w-full bg-black">
              <img src="@/assets/green_delta.png" alt="" class="h-[100px]" />
            </div>
            <div class="px-6 pb-10">
              <div
                class="font-bold text-xl pt-2 pb-2 border-b-2 border-gray-200"
              >
                Sign in
              </div>
              <!-- <p class="text-gray-700 text-base">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                    </p> -->

              <!-- <input v-model="username" type="text" class="input mb-3 mt-4 mr-2" style="width:200px;" placeholder="username" required>

                  <input v-model="password" type="password" class="md:flex appearance-none border-2 border-gray-400 rounded  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-gray-100 focus:border-gray-900" style="width:200px;" placeholder="Password" required> -->
              <div class="md:flex md:items-center pt-5">
                <div class="md:w-[120px] ml-[15px]">
                  <label
                    class="block text-gray-500 font-bold md:text-right pr-4"
                    for="inline-full-name"
                  >
                    Email:
                  </label>
                </div>
                <div class="md:w-[300px]">
                  <input
                    v-model="username"
                    class="md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-0 focus:bg-gray-100 focus:border-orange-700 w-[200px]"
                    type="email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="md:flex md:items-center mt-6">
                <div class="md:w-[100px] ml-[19px]">
                  <label
                    class="block text-gray-500 font-bold md:text-right pr-4"
                    for="inline-full-name"
                  >
                    Password:
                  </label>
                </div>
                <div class="md:w-[300px]">
                  <input
                    v-model="password"
                    class="md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-0 focus:bg-gray-100 focus:border-orange-700 w-[200px]"
                    type="password"
                    placeholder="password"
                  />
                </div>
              </div>
              <br />

              <button
                class="bg-buttonAccent1 hover:bg-buttonAccentHover w-[270px] text-white font-bold py-2 mx-1 rounded"
                type="submit"
                @click="submit"
              >
                Sign in
              </button>
              <br />
              <p class="mt-3 text-sm">
                Don't Remember the Password
                <span
                  class="border-b-2 border-red-700 text-red-700 hover:text-red-500 hover:border-red-500 cursor-pointer"
                  ><router-link to="/forgot_password">Click</router-link></span
                >
                here
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";
import NavbarNotLoggedIn from "@/components/NavbarNotLoggedIn.vue";
import api from "../../boot/axios.js";

const username = ref("");
const password = ref("");
const currentUser = ref("");
const instance = getCurrentInstance();

const submit = async () => {
  const loginForm = {
    email: username.value,
    password: password.value,
  };

  let jwtToken = "";

  await api
    .post("/auth/logins", loginForm, {
      withCredentials: true,
    })
    .then((_res_) => {
      jwtToken = _res_.data;

      if (_res_.status !== 200) {
        instance.proxy.$swal({
          toast: true,
          position: "bottom-end",
          icon: "error",
          title: "Authentication Failed",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
        username.value = "";
        password.value = "";
      }
    });

  console.log(jwtToken);
  api.defaults.headers.common["Authorization"] = `Bearer ${jwtToken.token}`;

  localStorage.setItem("r_token", jwtToken.r_token);
  localStorage.setItem("email", jwtToken.email);
  localStorage.setItem("role", jwtToken.role);
  localStorage.setItem("name", jwtToken.name);
  localStorage.setItem("id", jwtToken.id);

  await api
    .get(`/auth/user/${jwtToken.id}/`)
    .then((_res_) => {
      currentUser.value = _res_.data;
      instance.proxy.$router.push({ name: "Home" });
      instance.proxy.$swal({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: "You have successfully logged in!",
        timerProgressBar: true,
        showConfirmButton: false,
        timer: 2000,
      });
    })
    .catch((_err_) => {
      instance.proxy.$swal({
        toast: true,
        position: "bottom-end",
        icon: "error",
        title: "Authentication Failed",
        timerProgressBar: true,
        showConfirmButton: false,
        timer: 2000,
      });
    });

  // console.log('yooo', currentUser.value)
};
</script>

<style scoped>
.card {
  margin: 60px auto; /* Added */
  float: none; /* Added */

  padding: 40px;
}
</style>
