<template>
  <div>
    <sidebar></sidebar>

    <div :style="{ 'margin-left': sidebarwidth }">
      <!-- topbar -->
      <div
        class="w-full bg-white flex justify-between gap-5 py-3 px-2 shadow-lg"
      >
        <div class="w-1/3">
          <input
            type="text"
            id="first_name"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Search by Asset Id, Description, Category, Location, Group..."
            v-model="searchQuery"
            @input="currentPage = 1"
          />
        </div>
        <div
          class="flex flex-col md:flex-row gap-5 justify-end items-center text-sm"
        >
          <div class="flex flex-wrap gap-2">
            <button
              class="px-3 py-2 rounded-md text-sm"
              :class="[
                filterState === 'all'
                  ? 'bg-green-500 text-white'
                  : 'bg-gray-200 text-black',
              ]"
              @click="filterAll"
            >
              All
            </button>
            <button
              class="px-3 py-2 rounded-md"
              :class="[
                filterState === 'printed'
                  ? 'bg-green-500 text-white'
                  : 'bg-gray-200 text-black',
              ]"
              @click="filterPrinted"
            >
              Printed
            </button>
            <button
              class="px-3 py-2 rounded-md"
              :class="[
                filterState === 'not_printed'
                  ? 'bg-green-500 text-white'
                  : 'bg-gray-200 text-black',
              ]"
              @click="filterNotPrinted"
            >
              Not Printed
            </button>
          </div>

          <div class="flex flex-wrap items-center gap-2">
            <div>Total Assets :</div>
            <div class="font-bold border-r-2 pr-5">{{ totalAssest }}</div>

            <button
              @click="printSelected"
              :disabled="noneSelected"
              :style="{
                cursor: noneSelected ? 'not-allowed' : 'pointer',
                backgroundColor: noneSelected ? 'gray' : 'black',
              }"
              class="px-3 py-2 rounded-md bg-black text-white font-semibold hover:bg-orange-600"
            >
              Print Selected
            </button>
            <button
              @click="showPopup = !showPopup"
              class="px-3 py-2 rounded-md bg-black text-white font-semibold hover:bg-blue-600"
            >
              Upload
            </button>
            <button
              @click="exportToExcel"
              class="bg-black text-white font-semibold py-2 px-3 rounded hover:bg-green-500"
            >
              Export to Excel
            </button>
          </div>
        </div>
      </div>
      <!-- end of topbar -->
      <!-- main content -->
      <div class="px-5">
        <!-- pagination logic info -->
        <div class="flex justify-between items-center">
          <p class="font-semibold text-[18px]">Asset Information</p>
          <div class="py-2 flex justify-between gap-10">
            <div class="pagination text-sm flex gap-5">
              <button
                @click="prevPage"
                class="bg-blue-500 text-white py-1 px-3 rounded"
                :disabled="currentPage === 1"
                :style="{
                  cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                  backgroundColor: currentPage === 1 ? 'gray' : '',
                }"
              >
                Previous
              </button>

              <button
                @click="nextPage"
                class="bg-blue-500 text-white py-1 px-3 rounded"
                :disabled="currentPage === totalPages"
                :style="{
                  cursor:
                    currentPage === totalPages ? 'not-allowed' : 'pointer',
                  backgroundColor: currentPage === totalPages ? 'gray' : '',
                }"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <!-- end of pagination logic info -->
        <!-- table content -->
        <div class="pt-2">
          <div class="relative overflow-x-auto w-[]">
            <table class="text-[12px] text-left rtl:text-right text-black">
              <thead class="text-[10px] text-white uppercase bg-black">
                <tr>
                  <th scope="col" class="py-3 px-3"></th>
                  <th scope="col" class="py-3 px-3">Asset ID</th>
                  <th scope="col" class="py-3 px-4">Description</th>
                  <th scope="col" class="py-3 px-3">Aqucistion Date</th>
                  <th scope="col" class="py-3 px-3">Category</th>
                  <th scope="col" class="py-3 px-3">Location</th>
                  <th scope="col" class="py-3 px-3">Cost Center</th>
                  <th scope="col" class="py-3 px-3">Group</th>
                  <th scope="col" class="py-3 px-3">Account Set</th>
                  <th scope="col" class="py-3 px-3">Resp. Person</th>
                  <th scope="col" class="py-3 px-3">Life</th>
                  <th scope="col" class="py-3 px-3">Book Value</th>
                  <th scope="col" class="py-3 px-3">YTD Depreciation</th>
                  <th scope="col" class="py-3 px-3">
                    Accumulated Depreciation
                  </th>
                  <th scope="col" class="py-3 px-3">Net Value</th>
                  <th scope="col" class="py-3 px-3">Remarks</th>
                  <th scope="col" class="py-3 px-3">Upload Date</th>
                  <!-- <th scope="col" class="py-3 px-3">Last Upload Date</th> -->
                  <th scope="col" class="py-3 px-3">Last Print Date</th>
                  <th scope="col" class="py-3 px-2">Printed</th>
                  <th scope="col" class="py-3 px-2">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="data in displayed_data"
                  :key="data.id"
                  class="odd:bg-white even:bg-gray-50 border-b dark:border-gray-700"
                >
                  <td class="py-4 px-3">
                    <input
                      type="checkbox"
                      :value="data.asset_id"
                      v-model="selectedItems"
                      class="rounded-2xl"
                      @change="showResetButton = selectedItems.length > 0"
                    />
                  </td>
                  <td class="py-4 px-3">
                    {{ data.asset_id ? data.asset_id : "N/A" }}
                  </td>
                  <td class="py-4 px-4">{{ data.description }}</td>
                  <td class="py-4 px-3">{{ data.acquisition_date }}</td>
                  <td class="py-4 px-3">{{ data.category }}</td>
                  <td class="py-4 px-3">{{ data.location }}</td>
                  <td class="py-4 px-3">{{ data.cost_center }}</td>
                  <td class="py-4 px-3">{{ data.group }}</td>
                  <td class="py-4 px-3">
                    {{ data.account_set == "nan" ? "N/A" : data.account_set }}
                  </td>
                  <td class="py-4 px-3">
                    {{
                      data.responsible_person == "nan"
                        ? "N/A"
                        : data.responsible_person
                    }}
                  </td>
                  <td class="py-4 px-3">{{ data.life }}</td>
                  <td class="py-4 px-3">{{ formatNumber(data.book_value) }}</td>
                  <td class="py-4 px-3">
                    {{ formatNumber(data.ytd_depreciation) }}
                  </td>
                  <td class="py-4 px-3">
                    {{ formatNumber(data.accumulated_depreciation) }}
                  </td>
                  <td class="py-4 px-3">{{ formatNumber(data.net_value) }}</td>
                  <td class="py-4 px-3">{{ data.remarks }}</td>

                  <td class="py-4 px-3">
                    {{ dayjs(data.created_at).format("YYYY-MM-DD HH:mm:ss") }}
                  </td>
                  <!-- <td class="py-4 px-3">{{ data.last_upload_date }}</td> -->
                  <td class="py-4 px-3">
                    {{ dayjs(data.updated_at).format("YYYY-MM-DD HH:mm:ss") }}
                  </td>
                  <td class="py-4 px-2">
                    <span :class="{ 'text-green-500': data.printed }">{{
                      data.printed ? "True" : "False"
                    }}</span>
                  </td>
                  <td class="py-4 px-2">
                    <div class="flex justify-center gap-5">
                      <button @click="handlePrint(data)">Print</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- end of table content -->
        <!-- reset button for checkbox -->
        <div v-if="showResetButton" class="mt-4 flex justify-end">
          <button
            @click="resetSelectedItems"
            class="bg-red-500 text-white py-2 px-4 rounded"
          >
            Reset Checkbox
          </button>
        </div>
        <!-- end of reset button -->

        <div class="mt-4 flex justify-end"></div>
      </div>
      <!-- end of main content -->
    </div>

    <!-- pop up for upload excel -->
    <div v-if="showPopup">
      <div class="popupContainer">
        <div class="bg-white ml-20 z-30 w-[40rem] rounded-md">
          <div class="px-6 py-6 rounded-b-lg">
            <form @submit.prevent="">
              <div class="grid sm:grid-cols-2 gap-10 mt-10">
                <!-- name field -->

                <input
                  type="file"
                  accept=".xls,.xlsx"
                  @change="handleFileChange"
                />

                <p v-if="status">{{ status }}</p>
              </div>
              <div class="inline-flex justify-end items-end w-full gap-2 mt-10">
                <button
                  :disabled="isLoading"
                  class="text-green-600 border border-gray-300 rounded-lg p-2.5 hover:bg-green-500 hover:text-white inline-flex gap-2"
                  @click="uploadFile"
                >
                  Upload
                </button>

                <button
                  :disabled="isLoading"
                  @click="reloadPage"
                  class="text-red-600 border border-gray-300 rounded-lg p-2.5 hover:bg-red-500 hover:text-white inline-flex gap-2"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end of the popup -->
  </div>
</template>

<script setup>
import sidebar from "@/components/sidebar/Sidebar.vue";
import {
  ref,
  onMounted,
  onBeforeMount,
  getCurrentInstance,
  computed,
} from "vue";
import api from "../../boot/axios.js";
import * as XLSX from "xlsx";
import { sidebarwidth } from "@/store/index.js";
import topbar from "@/components/topbar/topbarView.vue";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import dayjs from "dayjs";

const productlist = ref([]);
const selectedItems = ref([]);
const showResetButton = ref(false);
const showPopup = ref(false);
const totalAssest = ref("");
//------**--------
const perPage = ref(10);
const currentPage = ref(1);
const searchQuery = ref("");
const filterState = ref("all");
// ---------**---------
const file = ref(null);
const status = ref("");
const isLoading = ref(false);

// calling api
const getallapi = async () => {
  await api.get("api/v1/productlist/").then((res) => {
    productlist.value = res.data.sort((a, b) => a.id - b.id);
    totalAssest.value = productlist.value.length;
    console.log(productlist.value, "checking ites");
  });
};
const handlePrint = async (data) => {
  try {
    const doc = new jsPDF({
      unit: "mm",
      format: [50, 80], // Set the paper size to match the barcode printer
    });
    const canvas = await QRCode.toCanvas(data.asset_id);
    const imgData = canvas.toDataURL("image/png");

    doc.addImage(imgData, "PNG", 10, 20, 30, 30);
    doc.setFontSize(6);
    doc.text(data.asset_id, 11, 50);
    doc.save(`${data.asset_id}.pdf`);

    await api.put(`api/v1/productlist/${data.id}/`, {
      printed: true,
    });
    data.printed = true; // Update the printed field locally
  } catch (error) {
    console.error("Error while printing:", error);
  }
};

// methods calling
// Custome Table component creatation with pagination and result per page option
const filteredProducts = computed(() => {
  const searchRegex = new RegExp(searchQuery.value.trim(), "i");
  let filtered = productlist.value.filter(
    (data) =>
      searchRegex.test(data.asset_id) ||
      searchRegex.test(data.description) ||
      searchRegex.test(data.category) ||
      searchRegex.test(data.lcoation) ||
      searchRegex.test(data.group)
  );

  if (filterState.value === "printed") {
    filtered = filtered.filter((data) => data.printed);
  } else if (filterState.value === "not_printed") {
    filtered = filtered.filter((data) => !data.printed);
  }
  filtered.sort((a, b) => a.id - b.id);
  currentPage.value = 1;
  return filtered;
});

const displayed_data = computed(() => {
  const startIndex = (currentPage.value - 1) * perPage.value;
  const endIndex = startIndex + perPage.value;
  return filteredProducts.value.slice(startIndex, endIndex);
});

const totalPages = computed(() =>
  Math.ceil(filteredProducts.value.length / perPage.value)
);

const changePerPage = () => {
  currentPage.value = 1;
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};
// end of table pagination

// print button filter
const filterAll = () => {
  filterState.value = "all";
  currentPage.value = 1; // Reset the currentPage
};

const filterPrinted = () => {
  filterState.value = "printed";
  currentPage.value = 1; // Reset the currentPage
};

const filterNotPrinted = () => {
  filterState.value = "not_printed";
  currentPage.value = 1; // Reset the currentPage
};
// multi-checkbox
const toggleSelectAll = () => {
  const allSelected =
    selectedItems.value.length === displayed_data.value.length;
  selectedItems.value = allSelected
    ? []
    : displayed_data.value.map((item) => item.asset_id);
};
// multi print option
const noneSelected = computed(() => selectedItems.value.length === 0);
const printSelected = async () => {
  try {
    const selectedData = displayed_data.value.filter((item) =>
      selectedItems.value.includes(item.asset_id)
    );

    const promises = selectedData.map((data) =>
      api.put(`api/v1/productlist/${data.id}/`, { printed: true })
    );

    await Promise.all(promises);

    selectedData.forEach((data) => {
      data.printed = true;
    });

    selectedItems.value = [];

    // Generate PDF with QR codes for selected data
    await generatePDF(selectedData);
  } catch (error) {
    console.error("Error while printing selected items:", error);
  }
};
// reset all checked
const resetSelectedItems = () => {
  selectedItems.value = [];
  showResetButton.value = false;
};
// turn off popup
const reloadPage = () => {
  showPopup.value = false; // Close the popup
  location.reload(); // Reload the page
};
// excel report generate
const exportToExcel = () => {
  const headerRow = [
    "Asset ID",
    "Description",
    "Acquisition Date",
    "Category",
    "Location",
    "Cost Center",
    "Group",
    "Account Set",
    "Resp. Person",
    "Life",
    "Book Value",
    "YTD Depreciation",
    "Accumulated Depreciation",
    "Net Value",
    "Remarks",
    "Upload Date",
    "Last Print Date",
    "Printed",
  ];

  const data = [
    headerRow,
    ...productlist.value.map((item) => [
      item.asset_id,
      item.description,
      item.acquisition_date,
      item.category,
      item.location,
      item.cost_center,
      item.group,
      item.account_set,
      item.responsible_person,
      item.life,
      item.book_value,
      item.ytd_depreciation,
      item.accumulated_depreciation,
      item.net_value,
      item.remarks == "nan" || item.remarks == null ? "" : item.remarks,
      dayjs(item.created_at).format("YYYY-MM-DD HH:mm:ss"),
      dayjs(item.updated_at).format("YYYY-MM-DD HH:mm:ss"),
      item.printed ? "True" : "False",
    ]),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "product_list.xlsx");
};

// generate pdf
async function generateQRCodeDataURI(text) {
  const qrCodeDataURI = await QRCode.toDataURL(text);
  return qrCodeDataURI;
}

async function generatePDF(selectedData) {
  const doc = new jsPDF({
    unit: "mm",
    format: [50, 80], // Set the paper size to match the barcode printer
  });

  for (let i = 0; i < selectedData.length; i++) {
    const item = selectedData[i];
    const qrCodeDataURI = await generateQRCodeDataURI(item.asset_id);

    // Add content to PDF (e.g., text fields, QR code image)
    doc.setFontSize(8);

    doc.addImage(qrCodeDataURI, "PNG", 10, 20, 30, 30); // Adjust the QR code size

    doc.setFontSize(6);
    doc.text(item.asset_id, 11, 50);

    // Add a new page only if it's not the last item
    if (i < selectedData.length - 1) {
      doc.addPage();
    }
  }

  // Save or download the PDF file
  doc.save("product_list.pdf");
}

// convert comma seperated
const formatNumber = computed(() => {
  return (value) => {
    if (!value) return "0";
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
});
// importing the excel data in the backend
const uploadFile = async () => {
  isLoading.value = true;
  status.value = "Uploading...Please Wait";
  try {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file.value);
    const readOpts = {
      cellText: false,
      cellDates: true,
    };

    fileReader.onload = async () => {
      const data = new Uint8Array(fileReader.result);
      const workbook = XLSX.read(data, readOpts);
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert the worksheet data to an array without header
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
        blankrows: true,
        raw: false,
        dateNF: 'd"/"m"/"yyyy',
      });

      const dataWithoutHeader = jsonData.slice(1);
      console.log(dataWithoutHeader, "check the data"); // Remove the header row

      // Split the data into chunks of 1000 items
      const chunks = [];
      for (let i = 0; i < dataWithoutHeader.length; i += 1000) {
        chunks.push(dataWithoutHeader.slice(i, i + 1000));
      }

      // Send each chunk to the backend API
      let allChunksUploaded = true;
      for (const chunk of chunks) {
        const success = await sendChunkToBackend(chunk);
        if (!success) {
          allChunksUploaded = false;
          break;
        } // Stop sending chunks if an error occurs
      }
      if (allChunksUploaded) {
        isLoading.value = false;
        status.value = "Upload Successful";
      } else {
        isLoading.value = false;
        status.value = "Error uploading file";
      }
    };
  } catch (error) {
    console.error("Error uploading file:", error);
    isLoading.value = false;
    status.value = "Error uploading file";
  }
};

const sendChunkToBackend = async (chunk) => {
  try {
    const response = await api.post("/api/v1/productUpload/", chunk);
    if (response.data.status === "success") {
      console.log("Chunk uploaded successfully");
      return true; // Return true to indicate success
    } else {
      console.error("Error uploading chunk:", response.data);
      return false; // Return false to indicate failure
    }
  } catch (error) {
    console.error("Error uploading chunk:", error);
    return false; // Return false to indicate failure
  }
};

// methods calling
// file-handler
const handleFileChange = (event) => {
  file.value = event.target.files[0];
};
// end of the method calling

onMounted(() => {
  getallapi();
});
</script>
<style scoped>
.popupContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.242);
}
</style>
