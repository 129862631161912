import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home.vue";
import Login from "../views/login.vue";

function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  if (localStorage.getItem("email") != null) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }

  if (isAuthenticated) {
    next();
  } else {
    next("/");
  }
}

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    // beforeEnter : guardMyroute,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    beforeEnter: guardMyroute,
  },
  {
    path: "/Table",
    name: "home2",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home2.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/scan",
    name: "Scan",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Scan.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/register_user",
    name: "RegisterUser",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegisterUser.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/register2",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register2.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/userList",
    name: "User-list",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/userListView.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/forgot_password",
    name: "forgot_password",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/forgotPasswordView.vue"),
  },
  {
    path: "/confirm_screen",
    name: "confirm_screen",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/confirmationScreenView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
